.header {
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;

  .header-logo {

    img {
      width: 120px;
    }
  }
  .header-icons {
    text-align: right;
  }
  .header-sub {
    border-top: 1px dashed #e1e1e1;
    text-align: left;
  }

  ul {
    border-bottom: none;

    li {
      border-bottom: none !important;
      margin-top: 15px !important;
      margin-bottom: 15px !important;
    }
  }
}
.header-language {
  text-transform: uppercase;
}
.header-auth {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .forLogo {
  padding: 20px 0;

    .logo {
      width: 140px;
    }
  }
  .right {
    text-align: right;
  }
}