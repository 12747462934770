.template_list-title {
  background: white;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 287.5%;
  color: #9D9EA9;

  .element {
    padding: 15px;

    &.right {
      text-align: right;
    }
  }
}

.template_list-element {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #3F3D56;

  .element {
    padding: 15px;

    &.right {
      text-align: right;
    }
  }
}
@font-family: Gilroy;@primary-color: #7264D6;