.authorization-block {
  height: 100vh;

  @media(max-width: 575px) {
    height: auto;
    margin-top: 80px;
  }
}
.registration-block {

  margin-top: 180px;
}
.authorization {
  background: white;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.08);
  border-radius: 32px;
  width: 100%;
  padding: 32px 40px;

  .drag {
    padding-bottom: 20px;

    .ant-upload {
      border-radius: 17px;
    }
  }

  h4 {
    margin-top: 30px;
    font-size: 16px;
  }

  @media(max-width: 575px) {
    box-shadow: none;
    border-radius: 0;
    padding: 20px 20px;
  }

  .examples {

    margin-top: 30px !important;
    margin-bottom: 30px !important;

    .select-module {
      background: #F5F5F8;
      padding: 15px 20px;
      border-radius: 17px;
      border: 1px dashed transparent;
      transition: 0.5s all;

      &.selected {
        border: 1px dashed #7264D6;
        transition: 0.5s all;
      }

      &:hover {
        cursor: pointer;
      }
    }

    p {
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 130%;
      letter-spacing: 0.2px;
      color: #3F3D56;
    }
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.2px;
      color: #9D9EA9;
    }

    img {
      width: 100%;

      &:hover {
        cursor: pointer;
      }
    }

    .preview-select {
      opacity: 1;
      transition: 1s all;
    }
    .preview {
      opacity: 0.4;
      transition: 1s all;
    }
  }
}
.authorization-figure-one {
  transition: 1s all;
  svg {
    z-index: -1;
    position: absolute;
    right: -10px;
    top: -80px;
    transition: 1s all;

    path {
      transition: 1s all;
    }
  }
}
.message-error {
  color: white;
  position: absolute;
  text-align: center;
  top: -30px;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.2px;
}
.authorization-figure-one-error {
  transition: 1s all;
  svg {
    z-index: -1;
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    top: -140px;
    transition: 1s all;
    transform: rotate(-30deg);

    path {
      transition: 1s all;
      fill: #FC8B8A;
    }
  }
}
.authorization-figure-two {
  svg {
    z-index: -2;
    position: absolute;
    right: -100px;
    top: -120px;
  }

  @media(max-width: 575px) {
    display: none;
  }
}
.authorization-figure-three {
  svg {
    z-index: -2;
    position: absolute;
    left: 20px;
    bottom: -100px;
  }

  @media(max-width: 575px) {
    display: none;
  }
}
.authorization-forgot {
  text-align: center;
  display: block;
  width: 100%;
  padding: 20px;
  border-radius: 17px;
  border: 1px solid #ebecec;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.2px;
}
@font-family: Gilroy;@primary-color: #7264D6;