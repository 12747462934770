.header-default {
  position: absolute;
  top: 24px;
  left: 0;
  right: 0;

  .for-logo {
    text-align: center;

    .logotype {
      width: 150px;
    }
  }

  .lang {
    padding: 15px 30px;

    a {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 12px;
      letter-spacing: 0.2px;
      color: #9D9EA9;
      padding: 9px 12px;
      background: #F5F5F8;
      border-radius: 7px;
    }
  }
  .logout {
    text-align: right;
    padding: 15px 30px;

    a {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 12px;
      letter-spacing: 0.2px;
      color: #9D9EA9;
      padding: 9px 12px;
      background: #F5F5F8;
      border-radius: 7px;
    }
  }
}
.header_slider {
  background: white;
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.08);
  border-radius: 0 32px 32px 0;

  @media(max-width: 991px) {
    width: 100%;
    z-index: 2;
  }

  .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
    right: auto;
    left: 0;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: rgba(114, 100, 214, 0.2);
    width: 90%;
    border-radius: 0 17px 17px 0;
  }

  ul {
    border-right: none;

    li {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 29px;
      /* or 161% */

      letter-spacing: 0.2px;
    }
  }

  .ant-layout {
    background: white;


  }

  .logo {
    padding: 20px 15px;
    text-align: center;

    img {
      width: 80%;
    }
  }
}
.ant-layout {
  background: transparent !important;

  .create_product {
    padding: 20px 25px 40px;

    a {
      display: block;
      text-align: center;
      width: 100%;
      padding: 20px;
      background: #F878C2;
      border-radius: 500px;
      border: none;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 12px;
      color: white;
      letter-spacing: 0.2px;
    }
  }
}
.ant-layout-sider {
  background: white !important;
}
.ant-layout-sider-trigger {
  background: transparent;

  svg {
    color: black;
  }
}
.top {
  margin-bottom: 36px;

  .logo {
    padding: 20px 15px;
    text-align: center;

    img {
      width: 60%;
    }
  }

  @media(max-width: 991px) {
    padding: 0 25px;
    background: #F5F5F8;
  }

  input {
    width: 100%;
    border: none;
    background: rgba(246, 245, 246, 0.85);
    border-radius: 39px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    display: flex;
    align-items: flex-end;
    padding: 15px 35px;
    outline:none;
  }
  .user_name {
    @media(max-width: 991px) {
      display: none;
    }
  }
  .name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #3F3D56;
    margin: 0;
  }
}
aside {
  @media(max-width: 991px) {
    display: none;
  }
}
.content-layout {
  margin: 20px 40px;

  @media(max-width: 991px) {
    margin: 0;
  }
}
@font-family: Gilroy;@primary-color: #7264D6;