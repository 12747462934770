@import 'fonts/stylesheet.css';
@import '~antd/dist/antd.less';
@import 'styles/template';

@import './less/header';
@import './less/signin';
@import './less/template';
@import './less/category';
@import './less/footer';
@import './less/delivery';
@import './less/product';
@import './less/form';
@import './less/zone';
@font-family: Gilroy;@primary-color: #7264D6;