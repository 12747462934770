html {
  overflow-x: hidden;
}
body {
  min-width: 320px;
  overflow-x: hidden;

}
h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 130%;
  letter-spacing: 0.2px;
  color: #3F3D56;
  margin: 0;

  @media(max-width: 767px) {
    margin-bottom: 10px;
  }
  @media(max-width: 575px) {
    font-size: 30px;
  }
}
h5 {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.2px;
  color: #3F3D56;
  margin-top: 30px;
}
.template-block {
  display: block;
  width: 100%;
}
.template-button-class {
  border-radius: 17px;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.2px;
  color: white;
  padding: 20px 30px;

  &:hover {
    cursor: pointer;
  }
}
.template-form {
  margin-top: 30px;

  input[type=password],
  input[type=email],
  input[type=text]
  {
    display: block;
    width: 100%;
    margin-bottom: 25px;
    background: #F5F5F8;
    border-radius: 17px;
    border: none;
    padding: 13px 23px;
    outline:none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;

    &:disabled {
      opacity: 0.5;
    }
  }
  input[type=submit],
  .template-button {
    background: #7264D6;
  }

}
.template-button-other {
  background: #F878C2;
  border-color: #F878C2;

  &:hover {
    background: rgba(248, 120, 194, 0.8);
    border-color: rgba(248, 120, 194, 0.8);
  }
}
p {
  &.sub {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 130%;
    letter-spacing: 0.2px;
    color: #3F3D56;
  }
  &.subTitle {
    font-style: normal;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: 0.2px;
    color: #3F3D56;
  }
  &.bold {
    font-weight: bold;
  }
  &.subTitle {
    font-style: normal;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.2px;
    color: #3F3D56;
  }
}

.title_page {
  margin-bottom: 30px;

  .right {
    text-align: right;

    @media(max-width: 991px) {
      text-align: left;
    }
  }
}


button.ant-btn-primary,
a.ant-btn-primary {
  height: auto;
  padding: 10px 25px;
  border-radius: 9px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.2px;
  line-height: 1.5715;

  @media(max-width: 991px) {
    padding: 7px 15px;
    border-radius: 7px;
    font-size: 12px;
  }
}

.site-layout {
  margin-left: 230px;
  padding: 30px;

  @media(max-width: 991px) {
    margin-left: 0;
    padding: 0;
  }
}
.template_content {
  @media(max-width: 991px) {
    padding: 0 20px;
  }
}

.button-main {
  background: #7264D6;
  border-radius: 34px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.2px;
  color: #FFFFFF;
  padding: 14px 22px;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.button-main_no-active {
  background: #F5F5F8;
  border-radius: 34px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.2px;
  color: #3F3D56;
  padding: 14px 22px;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.button-main_no-active-little {
  background: #F5F5F8;
  border-radius: 14px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.2px;
  color: #3F3D56;
  padding: 9px 16px;
  border: none;

  &.active {
    background: #7264D6;
    color: #FFFFFF;
  }

  &:hover {
    cursor: pointer;
  }
}

.button-action {
  background: transparent;
  border: none;
  display: inline-block;
  padding: 5px;
  margin: 5px;
  line-height: 0;
  border-radius: 5px;
  opacity: 0.6;
  transition: opacity 0.5s;

  &.edit {
    svg {
      path {
        fill: #9D9EA9;
      }
    }
  }
  &.delete {
    svg {
      path {
        stroke: #FD7F7E;
      }
    }
  }

  &:hover {
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.5s;
  }
}
.button-header {
  background: transparent;
  border: none;
  color: #9D9EA9;
  font-size: 16px;
}
.mobile-menu {
  background: #F5F5F8;
  margin-top: -35px;
  margin-bottom: 20px;

  ul {
    margin: 15px 0;

    li {
      list-style-type: none;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 160%;
      letter-spacing: 0.2px;

      a {
        color: #9D9EA9;
      }

      ul {
        margin: 15px 0 25px 0;
        padding: 0;

        li {
          font-size: 12px;
          line-height: 200%;
        }
      }
    }
  }

  .ant-menu {
    background: transparent !important;
  }
}
.mobile-padding {
  @media(max-width: 991px) {
    padding: 0 20px;
  }
}

.ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content {
  @media(max-width: 991px) {
    overflow-x: auto;
  }
}
.template_skeleton {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 1);

  .for_svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 33px;
    height: 33px;
  }
}
.template-content {
  position: relative;
}

.template-examples {
  opacity: 0.6;
  border: 1.5px dashed #e1e1e1;
  padding: 30px 30px 20px;
  border-radius: 12px;
  transition: all 1s;

  &:hover {
    opacity: 1;
    transition: all 1s;
  }

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 130%;
  }
  .description {
    font-style: normal;
    font-size: 14px;
    line-height: 160%;
    color: #9D9EA9;
  }
  .ps {
    font-style: normal;
    font-size: 11px;
    line-height: 160%;
    color: #9D9EA9;
  }
}

.product-filters-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.2px;
  color: #3F3D56;
  margin-top: 15px;
}
.product-filters-elements {
  span {
    background: #F5F5F8;
    border-radius: 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.2px;
    color: #3F3D56;
    padding: 9px 16px;
    border: none;
    margin-right: 5px;

    &:hover {
      cursor: pointer;
      background: #CFDCF6;
      color: #3F3D56;
    }

    &.active {
      background: #7264D6;
      color: #FFFFFF;
    }
  }
}
p.template-propertyList {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  color: #9D9EA9;

  span.element {
    border: 1px dashed #ebecec;
    padding: 4px 8px;
    border-radius: 500px;
    margin-right: 5px;
    white-space: nowrap;
  }
}