.template-title {
  .right {
    text-align: right;
  }
}

.all-label {
  display: block;
  margin: 25px 0 15px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
}
.label-inline {
  margin-left: 15px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
}
.work-button_block {
  margin-top: 30px;
}
.product-input_block_submit {
  margin-top: 120px;
}
.ant-upload.ant-upload-drag {
  height: auto !important;
}
.template-breadcrumb {
  margin-bottom: 30px;
}
.template-search {
  margin-bottom: 20px;
}
.template-language {
  text-align: center;
  margin-top: 20px;

  button {
    display: block;
    width: 100%;
    text-align: center;
    background: transparent;
    padding: 13px;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 600;
    border: none;

    &:hover {
      cursor: pointer;
    }

    &.active {
      color: #63D498;
    }
  }
}
.template-added {
  text-align: center;
  padding: 25px;
  color: #e1e1e1;
  font-weight: 600;
  border-radius: 10px;
  border: 2px dashed #e1e1e1;

  &:hover {
    border-color: #ebecec;
    cursor: pointer;
  }
}
.template-news-detail-edited {
  input {
    width: 100%;
    max-width: 800px;
    display: block;
    padding: 6px;
    font-size: 16px;
    font-weight: 400;
    border: none;
    outline: none;
    background: #ebecec;
    min-height: 60px;
  }
}
.image-item {
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.template-news-detail-old {
  width: 100%;
  max-width: 800px;
  display: block;
  padding: 6px;
  font-size: 16px;
  font-weight: 400;
  border: none;
  outline: none;
  position: relative;
  min-height: 60px;
  white-space: pre-line;
}
.template-news-buttons {
  position: relative;

  .delete {
    position: absolute;
    top: 30px;
    left: 850px;
  }

}
.home {

  .sub {
    font-size: 18px;
    font-weight: 600;
  }
  .title {
    line-height: 1.5;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;

    span {
      color: @primary-color;
      font-size: 28px;
      font-weight: 600;
      display: block;
    }
  }
}