.order_header {
  background: #FFFFFF;
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.08);
  border-radius: 32px;
  padding: 30px 40px 10px;
  margin-top: 20px;
}
.order_map {
  margin-top: 20px;
  background: #FFFFFF;
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.08);
  border-radius: 32px;
  padding: 0;

  div {
    border-radius: 32px;

    ymaps {
      border-radius: 32px;
    }
  }
}
.order-status {
  border-radius: 17px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.08);
  padding: 30px 30px 10px;
  transition: 3s all;

  .for {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 2;
  }

  .description {
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.4px;
    color: #9D9EA9;
    margin-top: 15px;
    display: block;
  }

  .status_input {
    font-weight: 800;
    font-size: 22px;
    width: 100%;
    outline: none;
    border: none;
  }

  .tops {
    position: absolute;
    top: 30px;
    right: 70px;
    z-index: 2;
    opacity: 1;
    width: 32px;
    height: 32px;
    background: #F5F5F8;
    text-align: center;
    border-radius: 500px;
    transition: 1s all;

    svg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      max-width: 70%;
    }
  }

  .icon_bg {
    opacity: 0.3;
    background: #F5F5F8;
    text-align: center;
    border-radius: 500px;
    width: 40px;
    height: 40px;
    position: relative;
    transition: 1s all;

    &.selected {
      opacity: 1;
      transition: 1s all;
      box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);
    }

    &:hover {
      cursor: pointer;
    }

    svg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      max-width: 70%;
    }
  }

  .order_id {
    color: #9D9EA9;
    font-weight: 800;
    margin-bottom: 10px;
  }
  .order_name {
    font-weight: 800;
    font-size: 22px;
  }
}
.select-main_block {
  margin-top: 15px;
  margin-bottom: 25px;
  width: 100%;

  .select-main {
    width: 100%;
    margin-top: 10px;

    .description {
      margin: 0;
    }

    .right {
      text-align: right;
    }
  }
}

@font-family: Gilroy;@primary-color: #7264D6;